import { CheckoutSuccessStatus } from "~/types/checkoutSuccess";

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));
  const { tokenUser } = storeToRefs(useTokenUserStore($pinia));
  const { checkoutSuccess } = storeToRefs(useCheckoutStore($pinia));

  if (checkoutSuccess.value === CheckoutSuccessStatus.ACCOUNT_FINALIZED) {
    return navigateTo("/account");
  }

  if (checkoutSuccess.value === CheckoutSuccessStatus.PAID) {
    // Always send to finalize-account if only PAID but not FINALIZED
    return navigateTo("/onboarding/finalize-account");
  }

  if (
    (user.value && user.value.premium_type && to.path !== "/account") ||
    checkoutSuccess.value === CheckoutSuccessStatus.ACCOUNT_FINALIZED
  ) {
    return navigateTo("/account");
  }

  if (
    tokenUser.value &&
    tokenUser.value.is_pro &&
    ["will_expire", "will_renew", "grace_period", "unknown"].includes(tokenUser.value.subscription_status)
  ) {
    return navigateTo("/error/already-pro");
  }
});
